@import '@fontsource/b612';
@import '@fontsource/roboto';
@import '@fontsource/open-sans';

@import '../js/Sidebar';

body {
    margin: 0;
    height: 100vh;
    color: black;
    font-family: sans-serif;
    background-color: white;
}

#root {
    height: 100%;
}

.main-content-container {
    flex: auto;
    overflow-y: auto;
}

.app {
    text-align: center;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;

    .btn-toggle {
        cursor: pointer;
        width: 35px;
        height: 35px;
        background: #353535;
        color: #fff;
        text-align: center;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        display: none;
    }

    @media (max-width: $breakpoint-md) {
        .btn-toggle {
            display: flex;
        }
    }

    main {
        padding: 24px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        header {
            display: flex;
            flex-direction: column;
            align-items: center;

            a {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            img {
                height: auto;
                max-height: 40em;
                width: 80%;
                margin: 2em;
            }

            div#infobox {
                margin: 0.5em auto;
                padding: 0.3em;
                font-family: 'B612', sans-serif;
                font-size: 150%;
                color: white;
                background-color: $logoMainColour;
                width: 80%;
                text-align: center;
            }
        }

        h1, h2, h3, h4 {
            clear: both;
            font-family: 'B612', serif;
        }

        p, a {
            color: black;
        }

        ul {
            display: block;
            line-height: 2;
        }

        #homepage {

            display: flex;
            flex-flow: row wrap;
            justify-content: space-evenly;

            div.topic {

                display: flex;
                flex-direction: column;
                margin: 40px;

                img.topic-img {
                    height: auto;
                    width: 20vw;
                    min-width: 200px;
                }

                a {
                    padding: 0.5em;
                    text-decoration: none;
                }
            }
        }

        div.page {

            margin: auto 1em;

            iframe#edoobox_saott {
                border: none;
                width: 100%;
                height: 600px;
            }
        }

        div.content-container {
            font-family: 'Open Sans', sans-serif;
            margin: auto;
            text-align: left;

            p {
                font-family: 'Open Sans', sans-serif;
                margin: 1em auto;
            }

            img {
                margin: 0.5em;
                max-width: 50%;
                float: right;
            }

            div.youtube {
                max-width: 90vw;
                float: right;
                clear: right;
            }
        }

        footer {
            margin-top: auto;
            text-align: center;

            a {
                margin: 1em;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
