
// General variables used
$logoMainColour: #199eb5;
$logoSecondaryColour: #bb4a96;
$rightColumnBackgroundColour: #91b0b5;

$sidebar-color: white;
$sidebar-bg-color: $logoMainColour;
$icon-bg-color: $logoMainColour;
$icon-size: 35px;

@import '~react-pro-sidebar/dist/scss/styles.scss';

.sidebar-container {
    flex: none;
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: hidden;
}

.pro-sidebar {

    .pro-sidebar-layout {
        min-height: 100vh;
    }

    .pro-sidebar-header {
        img {
            width: 90%;
            margin: 1em;
        }
    }

    .pro-sidebar-content {
    }

    .pro-sidebar-footer {

        font-size: 0.75rem;

        a {
            color: white;
            font-family: 'Open Sans', sans-serif;
            font-size: 0.75rem;
            text-decoration: none;
            white-space: nowrap;
        }

        .social-media-icons a {
            font-size: 1.25rem;
        }

        a + a {
            margin-left: 0.75rem;
        }

        div {
            margin: 1rem;
        }

        img {
            width: 9rem;
        }
    }
}
